<template>
  <router-view />
</template>

<script>
  export default {
    name: 'PrenotazioniIndex',
    data: () => ({
      expandOnHover: false,
    }),
  }
</script>
